import { Deserializable } from './deserializable.model'
import { SessionRestrictionsModel } from './session-restrictions.model'

export class Session implements api.SessionData, Deserializable {
  session_expires_at: string
  session_id: string
  user_id: string
  first_name: string
  last_name: string
  nickname: string
  email?: string
  iana_time_zone?: string
  personal_picture_url?: string
  session_checksum: string
  permissions?: string[]
  member_since_date: string
  member_id: string
  workgroup_code: string
  has_portrait: boolean
  is_datavision_user: boolean
  user_settings: api.UserSettings
  restrictions: SessionRestrictionsModel
  mobile_area_code: string
  mobile_country_code: string
  mobile_number: string
  realm_code: string
  contact: string
  is_direct_login: boolean
  can_upload_document: boolean
  profile_updated_at?: string
  is_client_sso: boolean
  deserialize(input: any): this {
    Object.assign(this, input)
    this.restrictions = new SessionRestrictionsModel().deserialize(input.restrictions)
    return this
  }
}
